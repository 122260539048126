import React, {FC} from 'react';
import {useRecoilValue} from 'recoil';
import {authenticatedUserState} from '../../state/auth';

const LoggedInUser: FC = () => {
  const user = useRecoilValue(authenticatedUserState);
  return (
    <div>
      {user?.profile?.name}
    </div>
  );
};

export {LoggedInUser};
