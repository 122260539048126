import React from 'react';
import {useRecoilValue} from 'recoil';
import {Redirect} from 'react-router-dom';
import {RouteWithTitle, RouteWithTitleProps} from './RouteWithTitle';
import {canUserSeeLinkState} from '../state/auth';
import {Dashboards} from '../types';

interface ProtectedRouteProps extends RouteWithTitleProps {
  dashboard: Dashboards;
}

const ProtectedRoute = ({dashboard, ...rest}: ProtectedRouteProps) => {
  const isAllowed = useRecoilValue(canUserSeeLinkState(dashboard))

  if (isAllowed) {
    return <RouteWithTitle {...rest}/>;
  }

  return <Redirect to="/"/>;
};

export {ProtectedRoute};
