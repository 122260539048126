import React from 'react'
import {RouteProps, Route, Redirect} from 'react-router-dom'
import {useRecoilValue} from 'recoil';
import {isUserAuthenticatedState} from '../state/auth';

const AuthenticatedRoute = ({component, ...rest}: RouteProps) => {
  const isAuthenticated = useRecoilValue(isUserAuthenticatedState)

  if (isAuthenticated) {
    return <Route {...rest} component={component}/>
  }

  return <Redirect to="/login"/>
}

export {AuthenticatedRoute}
