import axios from 'axios';

export const setAuthHeader = (token: string): void => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

export const getData = async (url: string): Promise<any | null> => {
  const response = await axios.get(url, defaultOptions);
  return response.data;
};

export const deleteData = async (url: string): Promise<any | null> => {
  const response = await axios.delete(url);
  return response.data;
};

export const patchData = async (url: string, data: any): Promise<any | null> => {
  const response = await axios.patch(url, data, defaultOptions)
  return response.data
}

export const postData = async (url: string, data: any): Promise<any | null> => {
  const response = await axios.post(url, data, defaultOptions)
  return response.data
}

export const postFileData = async (url: string, data: any): Promise<any | null> => {
  const response = await axios.post(url, data, defaultOptionsFile)
  return response.data
}

export const getDefaultOptions = () => ({
  method: 'post',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
    'Authorization': `Bearer ${sessionStorage.getItem("access_token")}`
  }
})

const defaultOptions = {
  headers: {
    'Access-Control-Allow-Origin': '*'
  }
};

const defaultOptionsFile = {
  headers: {
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*'
  }
}

export const apiHost = process.env.REACT_APP_API_HOST;
export const refundApiHost = process.env.REACT_APP_REFUND_API_HOST;
