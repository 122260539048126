import {Moment} from 'moment';

export enum Dashboards {
  Commission,
  Performance,
  Member,
  LivePerformance,
  Sales,
  ProtectFinancial,
  DynamicPerformance,
  DynamicPricing,
  Document,
	DynamicContent,
	Refund,
  Productivity,
	InsuranceProvider,
	Upload,
	Integration
}

export enum Roles {
	CommissionDashboardUser = 'CommissionDashboardUser',
	MemberDashboardUser = 'MemberDashboardUser',
	LivePerformanceDashboardUser = 'LivePerformanceDashboardUser',
	SalesDashboardUser = 'SalesDashboardUser',
	ProtectFinancialDashboardUser = 'ProtectFinancialDashboardUser',
	DynamicDashboardUser = 'DynamicDashboardUser',
	DynamicDashboardAdmin = 'DynamicDashboardAdmin',
	DocumentDashboardUser = 'DocumentsDashboardUser',
	RefundDashboardUser = 'RefundDashboardUser',
	ProductivityDashboardUser = 'ProductivityDashboardUser',
	InsuranceProviderDashboardUser = 'InsuranceProviderDashboardUser',
	UploadDashboardUser = 'UploadDashboardUser',
	IntegrationDashboardUser = 'IntegrationDashboardUser'
}

export const dashboardPaths: Record<Dashboards, string> = {
  [Dashboards.Commission]: '/commission',
  [Dashboards.Performance]: '/',
  [Dashboards.Member]: '/member',
  [Dashboards.LivePerformance]: '/live-performance',
  [Dashboards.Sales]: '/sales',
  [Dashboards.ProtectFinancial]: '/protect-financial',
  [Dashboards.DynamicPerformance]: '/dynamic-performance',
  [Dashboards.DynamicPricing]: '/dynamic-pricing',
  [Dashboards.Document]: '/document',
	[Dashboards.DynamicContent]: '/dynamic-content',
	[Dashboards.Refund]: '/refund',
	[Dashboards.Productivity]: '/productivity',
	[Dashboards.InsuranceProvider]: '/insurance-provider',
	[Dashboards.Upload]: '/upload',
	[Dashboards.Integration]: '/integration'
}

export const dashboardRoles: Record<Dashboards, string> = {
  [Dashboards.Commission]: Roles.CommissionDashboardUser,
  [Dashboards.Performance]: 'NotReady',
  [Dashboards.Member]: Roles.MemberDashboardUser,
  [Dashboards.LivePerformance]: Roles.LivePerformanceDashboardUser,
  [Dashboards.Sales]: Roles.SalesDashboardUser,
  [Dashboards.ProtectFinancial]: Roles.ProtectFinancialDashboardUser,
  [Dashboards.DynamicPerformance]: Roles.DynamicDashboardUser,
  [Dashboards.DynamicPricing]: Roles.DynamicDashboardAdmin,
  [Dashboards.Document]: Roles.DocumentDashboardUser,
	[Dashboards.DynamicContent]: Roles.DynamicDashboardAdmin,
	[Dashboards.Refund]: Roles.RefundDashboardUser,
	[Dashboards.Productivity]: Roles.ProductivityDashboardUser,
	[Dashboards.InsuranceProvider]: Roles.InsuranceProviderDashboardUser,
	[Dashboards.Upload]: Roles.UploadDashboardUser,
	[Dashboards.Integration] : Roles.IntegrationDashboardUser
}

export enum ProductType {
	All,
	EventProtect,
	RefundProtect
}

export enum NotificationMessageType {
	success,
	info,
	warning,
	error
}

export enum RegionType {
	All,
	Asia,
	Europe,
	NorthAmerica,
	SouthAmerica,
	CentralAmerica,
	Nordics,
	MiddleEastAfrica,
	India,
	China,
	Oceania
}

export enum TerritoryType {
	All,
	Americas,
	EMEAI,
	Asia
}

export enum MemberRegion {
	CentralAmerica = 3,
	SouthAmerica = 4,
	China = 5,
	NorthAsia = 6,
	SouthAsia = 7,
	Oceania = 8,
	Europe = 10,
	India = 12,
	Brazil = 13,
	NorthAmerica = 14,
	MiddleEast = 15,
	Africa = 16
}

export interface NotificationMessage {
	message: string;
	type: NotificationMessageType;
	stayOpen?: boolean;
	timeout?: number
}

export interface CommissionFilter extends Record<string, any> {
	dateFrom: string | null;
	dateTo: string | null;
	userId: number | null;
	memberId: number | null;
	productType?: ProductType | null;
	loggedInUser: string | undefined;
	salesTeamId?: number | null;
}

export interface CommissionFilter extends Record<string, any> {
	dateFrom: string | null;
	dateTo: string | null;
	userId: number | null;
	memberId: number | null;
	productType?: ProductType | null;
	loggedInUser: string | undefined;
	salesTeamId?: number | null;
}

export interface MemberFilter extends Record<string, any> {
	dateFrom: string;
	dateTo: string;
	memberId: number;
	eventId: any;
	mainProductId: number;
	productId: number;
	productTypeCode: string;
	countryCode: string;
	currencyCode: string;
	coreCurrencyCode: string;
	loggedInUser: string | undefined;
}

export interface InsuranceProviderFilter extends Record<string, any> {
	dateFrom: string;
	dateTo: string;
	insuranceProviderId: number;
	eventIds: number[];
	productId: number;
	rpTypeId: number;
	epTypeId: number;
	locationId: string;
	currencyCode: string;
	coreCurrencyCode: string;
	loggedInUser: string | undefined;
	insuranceProviderDataIds: string;
	brokerDataIds: string;
}

export interface IntegrationFilters extends Record<string, any> {
	dateFrom: string;
	dateTo: string;
	regions: number[];
	commercial: string;
	integrationType: number;
	loggedInUser: string | undefined;
}

export interface DocumentFilter extends Record<string, any> {
	actorId: number | null;
	loggedInUser: string | undefined;
	documentType: number | null;
	dateFrom: string;
	dateTo: string;
	insuranceProviderDataIds: string;
	isActorInsurer: boolean | null;
}

export interface UploadFilter extends Record<string, any> {
	loggedInUser?: string;
	errorId?: string;
}

export interface TransactionTableFilter extends Record<string, any> {
	dateFrom: string;
	dateTo: string;
	memberId: number;
	eventId: any;
	mainProductId: number;
	productId: number;
	productTypeCode: string;
	countryCode: string;
	currencyCode: string;
	coreCurrencyCode: string;
	loggedInUser: string | undefined;
	transactionSearchText: string;
	autoRefresh: number;
}

export interface RefundUserTableFilter extends Record<string, any> {
}

export interface DropdownData {
	id: number;
	name: string
}

export interface User extends DropdownData {
}

export interface Member extends DropdownData {
}

export interface Type extends DropdownData {
}

export interface Actor extends DropdownData {
	actorId: number;
	actorName: string;
	isMember: boolean;
	isInsurer: boolean;
}

export interface Document extends DropdownData {
}

export interface ProductTypes extends DropdownData {
	productId: number;
	productCode: string;
	productName: string;
}

export interface Event extends DropdownData {
	eventId: number;
	eventName: string;
}

export interface Location extends DropdownData {
	transactionCountryId: number;
	transactionCountryCode: string;
	transactionCountryName: string;
}

export interface Commercial extends DropdownData{
	commercialName: string;
	commercialEmail: string;
}

export interface Region extends DropdownData {
	value: number;
}

export interface MainProduct extends DropdownData {
	productName: string;
}

export interface Currency extends DropdownData {
	currencyCode: string;
	symbol: string;
}

export interface Regions {
	MemberId: number;
	MemberName: string;
}

export interface UploadProductType extends DropdownData {
	type: string
}

export interface UploadActionType extends DropdownData {
}

export interface Timezone extends DropdownData {
}

export interface SalesTeam extends DropdownData {
}

export interface InsuranceProvider extends DropdownData {
}

export interface ChartData {
	commission: number[];
	revenue: number[];
}

export interface ConversionByValueBand {
	ValueBand: string;
	ConversionRate: number;
}

export interface DateFilterToggle {
	label: string;
	dateFrom: Moment;
	dateTo: Moment
}

export interface RegionPerformanceData {
	name: string;
	weekTotal: number;
	monthTotal: number;
	yearTotal: number;
}

export interface SummaryResultData {
	TotalEventCount: any;
	TotalProtectionCost: any;
	TotalProtectionRate: any;
	TotalTransactionValue: any;
	TotalTransactionVolume: any;
}

export type RegionMaps = {
	RegionSummaries: RegionSummaries;
}

export interface RegionSummaries extends RegionMaps {
	Europe: Europe;
	Unknown: Unknown;
	Africa: Africa;
	"North America": NorthAmerica;
	"South America": SouthAmerica;
	Asia: Asia;
	Oceania: Oceania;
}

export interface RegionSummary {
	EpTransactionProtectionValueWTD: number;
	RpTransactionProtectionValueWTD: number;
	EpTransactionProtectionValueMTD: number;
	RpTransactionProtectionValueMTD: number;
	EpTransactionProtectionValueYTD: number;
	RpTransactionProtectionValueYTD: number;
	EpTransactionValueInsuredWTD: number;
	RpTransactionValueInsuredWTD: number;
	EpTransactionValueInsuredMTD: number;
	RpTransactionValueInsuredMTD: number;
	EpTransactionValueInsuredYTD: number;
	RpTransactionValueInsuredYTD: number;
	EpTransactionVolumeInsuredWTD: number;
	RpTransactionVolumeInsuredWTD: number;
	EpTransactionVolumeInsuredMTD: number;
	RpTransactionVolumeInsuredMTD: number;
	EpTransactionVolumeInsuredYTD: number;
	RpTransactionVolumeInsuredYTD: number;
	EpTransactionCountInsuredWTD: number;
	RpTransactionCountInsuredWTD: number;
	EpTransactionCountInsuredMTD: number;
	RpTransactionCountInsuredMTD: number;
	EpTransactionCountInsuredYTD: number;
	RpTransactionCountInsuredYTD: number;
	CountriesData: CountriesData[];
	RegionName: string;
	RegionId: number;
}

export interface Europe extends RegionSummary {
}

export interface Unknown extends RegionSummary {
}

export interface Africa extends RegionSummary {
}

export interface NorthAmerica extends RegionSummary {
}

export interface SouthAmerica extends RegionSummary {
}

export interface Asia extends RegionSummary {
}

export interface Oceania extends RegionSummary {
}

export interface CountriesData {
	CountryCodeTwoLetter: string;
	CountryCodeThreeLetter: string;
	CountryName: string;
	EpTransactionProtectionValue: number;
	EpTransactionValueInsured: number;
	EpTransactionVolumeInsured: number;
	EpTransactionCountInsured: number;
	RpTransactionProtectionValue: number;
	RpTransactionValueInsured: number;
	RpTransactionVolumeInsured: number;
	RpTransactionCountInsured: number;
	TransactionDate: Date;
}

export interface ProtectFinancialFilter extends Record<string, string> {
	dateFrom: string;
	dateTo: string;
}
