import React from 'react'
import classNames from 'classnames'
import {RecoilRoot, useRecoilValue} from 'recoil'
import {BrowserRouter} from 'react-router-dom'
import {isSidebarOpenState} from './state/ui'
import {Routes} from './routes'
import './assets/styles/App.scss'
import {initialState} from './state/initial'

const Main = () => {
	const isSidebarOpen = useRecoilValue<boolean>(isSidebarOpenState)

	return (
		<div
			className={classNames('perfect-scrollbar-on', {
				'sidebar-mini': !isSidebarOpen,
				'nav-open': isSidebarOpen
			})}
		>
			<BrowserRouter>
				<Routes/>
			</BrowserRouter>
		</div>
	)
}

function App() {
	return (
		<RecoilRoot initializeState={initialState}>
			<Main/>
		</RecoilRoot>
	)
}

export default App
