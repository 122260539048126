import React, {FC} from 'react'
import {Nav} from 'reactstrap'
import {NavLink} from 'react-router-dom'
import {useRecoilValue} from 'recoil'
import {canUserSeeLinkState} from '../../state/auth'
import {dashboardPaths, Dashboards} from '../../types'
import classNames from 'classnames'

interface LinkProps {
  dashboard: Dashboards
  title: string
  icon: string
}

const Link = (props: LinkProps) => {
  const { dashboard, title, icon } = props
  const canViewLink = useRecoilValue(canUserSeeLinkState(dashboard))

  if (!canViewLink) {
    return null
  }

  return (
    <li>
      <NavLink className="inactive" activeClassName="active" exact to={dashboardPaths[dashboard]}>
        <i className={classNames('tim-icons', icon)}/>
        <p>{title}</p>
      </NavLink>
    </li>
  )
}

const Sidebar: FC = () => {
  return (
    <div className="sidebar h-100">
      <div className="sidebar-wrapper">
        <Nav>
          <Link dashboard={Dashboards.Commission} title="Commission" icon="icon-coins"/>
          <Link dashboard={Dashboards.Document} title="Documents" icon="icon-single-copy-04"/>
          <Link dashboard={Dashboards.DynamicContent} title="Dynamic Content" icon="icon-components"/>
          <Link dashboard={Dashboards.DynamicPerformance} title="Dynamic Performance" icon="icon-vector"/>
          <Link dashboard={Dashboards.DynamicPricing} title="Dynamic Pricing" icon="icon-atom"/>
          <Link dashboard={Dashboards.InsuranceProvider} title="Insurance Provider" icon="icon-wallet-43"/>
          <Link dashboard={Dashboards.LivePerformance} title="Live Performance" icon="icon-trophy"/>
          <Link dashboard={Dashboards.Member} title="Members" icon="icon-chart-pie-36"/>
          <Link dashboard={Dashboards.Performance} title="Performance" icon="icon-trophy"/>
          <Link dashboard={Dashboards.Productivity} title="Productivity" icon="icon-trophy"/>
          <Link dashboard={Dashboards.ProtectFinancial} title="Protect Financial" icon="icon-coins"/>
          <Link dashboard={Dashboards.Refund} title="Refund" icon="icon-notes"/>
          <Link dashboard={Dashboards.Sales} title="Sales" icon="icon-chart-bar-32"/>
          <Link dashboard={Dashboards.Integration} title="Integration" icon="icon-laptop"/>
          <Link dashboard={Dashboards.Upload} title="Upload" icon="icon-upload"/>
        </Nav>
      </div>
    </div>
  );
};

export {Sidebar};
