import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {signInRedirectCallback} from '../../services/user-service';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {authenticatedUserState} from '../../state/auth';
import {setAuthHeader} from '../../api/common';
import {defaultRouteState} from '../../state/ui';

const SignInRedirect = () => {
  const history = useHistory();
  const setUser = useSetRecoilState(authenticatedUserState);
  const defaultRoute = useRecoilValue(defaultRouteState)

  useEffect(() => {
    signInRedirectCallback()
      .then(user => {
        setAuthHeader(user.id_token);
        setUser(user);
        history.push(defaultRoute);
      })
      .catch(err => {
        console.log('sign in redirect failed', err);
      });
  }, [history, setUser, defaultRoute]);

  return (
    <>
    </>
  );
};

export {SignInRedirect};
