import React, {FC, lazy, Suspense, useRef} from 'react'
import classNames from 'classnames'
import {Redirect, Route, Switch, useLocation} from 'react-router-dom'
import {Sidebar} from '../components/Sidebar'
import {DashboardNavbar} from '../components/Navbar/DashboardNavbar'
import {HomeView} from '../views/Home/HomeView'
import {TokenManager} from '../components/Auth/TokenManager'
import {NotificationWrapper} from '../components/Notifications/NotificationWrapper'
import {ProtectedRoute} from '../routes/ProtectedRoute'
import {dashboardPaths, Dashboards} from '../types'
import {Footer} from '../components/Footer'

const CommissionView = lazy(() => import('../views/Commission/CommissionView'))
const LivePerformanceView = lazy(() => import('../views/LivePerformance/LivePerformanceView'))
const MemberView = lazy(() => import('../views/Member/MemberView'))
const ProductivityView = lazy(() => import('../views/Productivity/ProductivityView'))
const ProtectFinancialView = lazy(() => import('../views/ProtectFinancial/ProtectFinancialView'))
const DynamicPerformanceView = lazy(() => import('../views/DynamicPerformance/DynamicPerformanceView'))
const DynamicPricingView = lazy(() => import('../views/DynamicPricingAnalysis/DynamicPricingAnalysisView'))
const DynamicContentView = lazy(() => import('../views/DynamicContent/DynamicContentView'))
const DocumentView = lazy(() => import('../views/Document/DocumentView'))
const RefundView = lazy(() => import('../views/Refund/RefundView'))
const InsuranceProviderView = lazy(() => import('../views/InsuranceProvider/InsuranceProviderView'))
const SalesView = lazy(() => import('../views/Sales/SalesView'))
const UploadView = lazy(() => import('../views/Upload/UploadView'))
const IntegrationView = lazy(() => import('../views/Integration/IntegrationView'))

const DashboardLayout: FC = () => {
	const containerRef = useRef<HTMLDivElement>(null)
	const location = useLocation()

	return (
		<div
			className={classNames('wrapper', {
				'hide-scroll': location.pathname === '/live-performance'
			})}
			ref={containerRef}
		>
			<NotificationWrapper/>
			<TokenManager/>
			<Sidebar/>
			<div className="main-panel">
				<DashboardNavbar/>
				<Suspense fallback={<div>Loading...</div>}>
					<Switch>
						<ProtectedRoute
							exact
							path="/commission"
							component={CommissionView}
							title="Commission Dashboard"
							dashboard={Dashboards.Commission}
						/>
						<ProtectedRoute
							exact
							path="/live-performance"
							component={LivePerformanceView}
							title="Live Performance"
							dashboard={Dashboards.LivePerformance}
						/>
						<ProtectedRoute
							exact
							path="/member"
							component={MemberView}
							title="Member Dashboard"
							dashboard={Dashboards.Member}
						/>
						<ProtectedRoute
							exact
							path="/productivity"
							component={ProductivityView}
							title="Productivity Dashboard"
							dashboard={Dashboards.Productivity}
						/>
						<ProtectedRoute
							exact
							path="/protect-financial"
							component={ProtectFinancialView}
							dashboard={Dashboards.ProtectFinancial}
							title="Protect Financial Dashboard"
						/>
						<ProtectedRoute
							exact
							path={dashboardPaths[Dashboards.DynamicPerformance]}
							component={DynamicPerformanceView}
							dashboard={Dashboards.DynamicPerformance}
							title="Refund Protect - Dynamic Performance Dashboard"
						/>
						<ProtectedRoute
							exact
							path={dashboardPaths[Dashboards.DynamicPricing]}
							component={DynamicPricingView}
							dashboard={Dashboards.DynamicPricing}
							title="Refund Protect - Dynamic Pricing Analysis Dashboard"
						/>
						<ProtectedRoute
							exact
							path={dashboardPaths[Dashboards.DynamicContent]}
							component={DynamicContentView}
							dashboard={Dashboards.DynamicContent}
							title="Refund Protect - Dynamic Content Dashboard"
						/>
						<ProtectedRoute
							exact
							path={dashboardPaths[Dashboards.Document]}
							component={DocumentView}
							dashboard={Dashboards.Document}
							title="Documents"
						/>
						<ProtectedRoute
							exact
							path={dashboardPaths[Dashboards.Refund]}
							component={RefundView}
							dashboard={Dashboards.Refund}
							title="Refund Dashboard"
						/>
						<ProtectedRoute
							exact
							path={dashboardPaths[Dashboards.InsuranceProvider]}
							component={InsuranceProviderView}
							dashboard={Dashboards.InsuranceProvider}
							title="Insurance Provider Dashboard"
						/>
						<ProtectedRoute
							exact
							path={dashboardPaths[Dashboards.Sales]}
							component={SalesView}
							dashboard={Dashboards.Sales}
							title="Sales Dashboard"
						/>
						<ProtectedRoute
							exact
							path={dashboardPaths[Dashboards.Upload]}
							component={UploadView}
							dashboard={Dashboards.Upload}
							title="Upload Dashboard"
						/>
						<ProtectedRoute
							exact
							path={dashboardPaths[Dashboards.Integration]}
							component={IntegrationView}
							dashboard={Dashboards.Integration}
							title="Integration Dashboard"
						/>
						<Route exact path="/home" component={HomeView}/>
						<Redirect to="/home"/>
					</Switch>
				</Suspense>
				<Footer/>
			</div>
		</div>
	)
}

export {DashboardLayout}
