import React from 'react'
import logoUrl from "../../assets/img/PG_Logo_white.svg";
import {Badge, Col, Container, Row} from "reactstrap";
import moment from 'moment'

const Footer = () => {
    const copyrightText = `© Copyright Protect International SA ${moment().format('YYYY')}`
    return (
        <Container fluid className="custom-footer-color">
            <Row className="py-2">
                <Col>
                    <img src={logoUrl} alt="Protect Group" width={85} className="float-right ml-2"/>
                    <small
                        className="text-white chart-summary-badge-radius chart-summary-responsive-text float-right">
                        <Badge color="light">{copyrightText}</Badge>
                        <Badge color="dark">All Rights Reserved.</Badge>
                    </small>
                </Col>
            </Row>
        </Container>
    )
}

export {Footer}
