import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {AuthenticatedRoute} from './AuthenticatedRoute';
import {Login, SignInRedirect, SilentRefresh} from '../views/Auth';
import {DashboardLayout} from '../layouts/DashboardLayout';

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/signin-oidc" component={SignInRedirect}/>
      <Route exact path="/login" component={Login} />
      <Route exact path="/silent-refresh" component={SilentRefresh}/>
      <AuthenticatedRoute path="/">
        <DashboardLayout/>
      </AuthenticatedRoute>
    </Switch>
  )
}

export { Routes }
