import React, {useEffect} from 'react';
import {RouteProps} from 'react-router-dom';
import {useSetRecoilState} from 'recoil';
import {dashboardTitleState} from '../state/ui';
import {AuthenticatedRoute} from './AuthenticatedRoute';

export interface RouteWithTitleProps extends RouteProps {
  title: string;
}

export const RouteWithTitle = (props: RouteWithTitleProps) => {
  const {title, ...rest} = props;
  const setDashboardTitle = useSetRecoilState(dashboardTitleState);

  useEffect(() => {
    document.title = `Protect Group - ${title}`;
    setDashboardTitle(title);
  });

  return <AuthenticatedRoute {...rest} />;
};
