import React, {FC} from 'react';

const HomeView: FC = () => {
  return (
    <div className="content">
      <div className="d-flex justify-content-center align-items-center">
        <h1 className="text-white">Protect Group Dashboard</h1>
      </div>
    </div>
  );
};

export {HomeView};
