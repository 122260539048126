import React, {useEffect, useRef, useState, memo} from 'react';
import classNames from 'classnames';
import {useRecoilValue} from 'recoil';
import {notificationMessageState} from '../../state/ui';
import {Toast, ToastBody} from 'reactstrap';
import {NotificationMessage, NotificationMessageType} from '../../types';

const NotificationWrapper = memo(() => {
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const notificationMessage = useRecoilValue<NotificationMessage | null>(notificationMessageState);
  const timeoutRef = useRef<any>();

  useEffect(() => {
    setShowNotification(notificationMessage !== null);

    if (!!notificationMessage && !notificationMessage.stayOpen) {
      timeoutRef.current = setTimeout(() => {
        setShowNotification(false);
      }, notificationMessage.timeout ?? 5000);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [notificationMessage]);

  const handleClick = () => setShowNotification(false);

  return (
    <Toast
      onClick={handleClick}
      className={classNames('w-50', {
        'show': showNotification,
        'hide': !showNotification,
        'info': notificationMessage?.type === NotificationMessageType.info,
        'error': notificationMessage?.type === NotificationMessageType.error,
        'success': notificationMessage?.type === NotificationMessageType.success
      })}
    >
      <ToastBody>
        <div className="d-flex align-items-center">
          <i
            className={classNames('tim-icons', 'pr-3',{
              'icon-bell-55': notificationMessage?.type === NotificationMessageType.info,
              'icon-alert-circle-exc': notificationMessage?.type === NotificationMessageType.error,
              'icon-check-2': notificationMessage?.type === NotificationMessageType.success
            })}
          />
          <span>
            {notificationMessage?.message}
          </span>
        </div>
      </ToastBody>
    </Toast>
  );
});

export {NotificationWrapper};
